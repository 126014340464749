<script setup>
import { useApp } from "~/composables/useApp";

const { htmlElements } = useApp();
</script>
<template>
  <VNavigationHeader
    @goback="htmlElements.homeScreensEl.goPrev()"
    label="History of reading"
    class="sticky mx-2"
  ></VNavigationHeader>
  <VHistoryList class="mt-4" />
</template>
